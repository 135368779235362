import React from "react";
import {Select} from "antd";
import {getCookie, setCookie} from "../../enitities/Cookie";

const searchResult = async (api, token, query) => {
  return fetch(`${api}warehouses/?token=${token}&name=${query}`)
    .then((response) => response.json())
    .then((body) =>
      body.result.map((user) => ({
        label: `${user.name}`,
        value: user.id,
      }))
    );
};

class WareHousesAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      value: undefined,
    };
  }

  async componentDidMount() {
    const option = await searchResult(this.props.api, this.props.token, "");
    this.setState({
      options: option,
      value: this.props.nodefaultvalue
        ? null
        : this.props.value
          ? this.props.value
          : null,
    });

    if (!this.props.nodefaultvalue) {
      this.setState({
        value: this.props.value ? this.props.value : option[0]?.value,
      });
    } else {
      this.props.onSelect(
        this.props.value ? this.props.value : getCookie("base_warehouse") || option[0]?.value
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({value: this.props.value});
    }
  }

  handleSelect = (value) => {
    this.setState({value: value});
    if (getCookie("cookieAccept") === "true") setCookie("base_warehouse", value, 365);
  };

  filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  render() {
    const {options, value} = this.state;

    return (
      <Select
        {...this.props}
        showSearch
        options={options}
        filterOption={this.filterOption}
        onChange={(value) => this.handleSelect(value)}
        value={value}
      ></Select>
    );
  }
}

export default WareHousesAutocomplete;
